import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { PositionResource } from '@/resource/model';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Query, Paging } from '@/api/base';
import { departmentService, positionService } from '@/api';
import { Message, MessageBox } from 'element-ui';
import { cloneDeep } from 'lodash';
import {
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  messageError,
  translation
} from '@/utils';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { ResourceStatusEnum } from '@/resource/enum';
import { MessageBoxData } from 'element-ui/types/message-box';
import { CascaderOption } from 'element-ui/types/cascader';
import AddPosition from './add-positon/add-position.vue';
@Component({
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddPosition }
})
export default class Position extends Vue {
  public tableOption: OsTableOption<PositionResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    },
    {
      type: 'Cascader',
      field: 'depIdList',
      label: 'department.department',
      className: 'department',
      option: {
        placeholder: 'department.selectDepartment',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false,
          multiple: true
        }
      },
      optionData: []
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'organize:position:save',
      handleClick: (): void => {
        this.addPosition();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'organize:position:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeletePosition();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'organize:position:editStatus',
      handleClick: (): void => {
        this.batchUpdatePositionStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'organize:position:editStatus',
      handleClick: (): void => {
        this.batchUpdatePositionStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<PositionResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'posCode',
      label: 'position.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    { prop: 'posName', label: 'position.name', minWidth: '100px', showOverflowTooltip: true },
    {
      prop: 'parentName',
      label: 'position.parentName',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'depName',
      label: 'department.departmentName',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '80px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<PositionResource> = {
    fixed: 'right',
    width: '220px',
    operations: [
      {
        operationType: 'add',
        type: 'text',
        label: 'button.add',
        icon: 'el-icon-circle-plus-outline',
        permissionCode: 'organize:position:save',
        handleClick: (position: PositionResource): void => {
          this.addChildPosition(position);
        }
      },
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'organize:position:edit',
        handleClick: (position: PositionResource): void => {
          this.editPosition(position);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'organize:position:delete',
        handleClick: (position: PositionResource): void => {
          this.deletePosition(position);
        }
      }
    ]
  };
  public editRow: PositionResource | null = null;
  public dialogVisible = false;
  public pageTotal = 0;
  public departmentOptions: Array<CascaderOption> = [];
  /**
   * 上级岗位，用于点击行新增时，为新增页面快捷指定上级岗位
   */
  public parentPosition: null | { departmentId: number; id: number } = null;
  private selectedRows: Array<PositionResource> = [];
  private queryForm: Query<{
    keywords: string;
    status: ResourceStatusEnum | null;
    depIdList: Array<number>;
  }> = {
    keywords: '',
    status: null,
    depIdList: []
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public created(): void {
    this.loadData();
    this.getDepartments();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.positionTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public handlePaging(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
    this.parentPosition = null;
  }

  public handleSelectionChange(selectedData: Array<PositionResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: PositionResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<PositionResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') {
        x.disabled = value.length === 0;
      }
    });
  }

  private addPosition(): void {
    this.openPositionDialog();
  }

  private editPosition(position: PositionResource): void {
    this.editRow = cloneDeep(position);
    this.openPositionDialog();
  }

  private addChildPosition(position: PositionResource): void {
    this.parentPosition = {
      departmentId: position.depId,
      id: position.id
    };
    this.openPositionDialog();
  }

  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }

  private deletePosition(data: PositionResource): void {
    this.deleteConfirm()
      .then(async () => {
        try {
          await positionService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeletePosition(): Promise<void> {
    this.deleteConfirm()
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await positionService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openPositionDialog(): void {
    this.dialogVisible = true;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    positionService
      .getList(this.queryForm as PositionResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data || [];
        this.pageTotal = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 批量修改岗位状态
   * @param status 状态 启用还是禁用
   */
  private batchUpdatePositionStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    positionService
      .batchUpdatePositionStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async getDepartments(): Promise<void> {
    try {
      const resources = await departmentService.getAllDepartments();
      const deptQuery = this.queryItemsOption.find(x => x.field === 'depIdList');
      this.departmentOptions = departmentService.handleCascaderOption(resources);
      deptQuery!.optionData = this.departmentOptions;
    } catch (error) {
      messageError(error);
    }
  }
}
